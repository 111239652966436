@charset "UTF-8";
body {
  /* about us */
  /* about us */
  /* store module*/
  /* store module*/
  /* Manufacturer Page */
  /* Manufacturer Page */
  /* Search-list */
  /* Search-list */
  /* Alerts */
  /* Alerts */
  /* Checkout */
  /* checkout */
  /* magnific-popup */
  /* magnific-popup */
  /* product page */
  /* product page*/
  /* modal window */
  /* modal window */
  /* calendar */
  /* calendar */
  /* carousel */
  /* carousel */
  /* Ordering */
  /* Ordering */
  /* Maine menu */
  /* Maine menu */
}

body .short-name {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

body .information ul {
  padding-left: 5px;
  margin-bottom: 35px;
}

body .information ul li:before {
  content: "—";
  position: relative;
  left: -5px;
}

body .information ul li {
  list-style: none;
}

body .information ul ul {
  padding-left: 30px;
}

body .information ul ul li {
  list-style-type: disc;
}

body .information ul ul li:before {
  display: none;
}

body .information ol {
  padding-left: 16px;
  margin-bottom: 35px;
}

body .store .panel-default {
  padding-bottom: 20px;
  border-radius: 0;
  box-shadow: none;
}

body .store p {
  margin-top: 20px;
  padding: 0 15px;
}

body .store .general a {
  padding: 5px 15px 5px 30px;
}

body .store a {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  text-decoration: none;
  padding: 5px 10px;
  display: inline-block;
}

body .store .panel span {
  padding-left: 15px;
}

body .alphabet_list .alphabet {
  font-size: 18px;
}

body .alphabet_list .alphabet a {
  font-size: 15px;
}

body .alphabet_list .alphabet_h2 {
  font-size: 30px;
  padding: 20px 0;
  margin: 0;
  flex: 0 0 80px;
  font-family: "Yeseva One", cursive;
  float: left;
  border: none;
}

body .alphabet_list .alphabet_category {
  line-height: 69px;
  margin-left: 0;
  margin-left: 70px;
}

body .alphabet_list .alphabet_category a {
  text-decoration: none;
}

body .alphabet_list .alphabet_category a:before {
  content: "\f0da";
  font-size: 15px;
  padding-right: 5px;
  display: inline-block;
  font-family: FontAwesome;
}

body .alphabet_list .alphabet_category span {
  padding-right: 6px;
}

body .alphabet_list .alphabet_category .title {
  margin-left: 15px;
  float: left;
}

body .search-list .dropdown-menu {
  min-width: 260px;
  padding: 0;
  margin-top: 3px;
}

body .search-list .dropdown-menu li {
  margin: 5px;
  font-size: 13px;
  display: flex;
}

body .search-list .dropdown-menu li:hover {
  cursor: pointer;
}

body .search-list .dropdown-menu li .title-search {
  padding-top: 5px;
}

body .search-list .dropdown-menu li span img {
  padding: 10px;
  text-align: center;
}

body .alert {
  position: relative;
  line-height: 32px;
  color: #fff;
  padding: 4px 30px 4px 6px;
}

body .alert i {
  float: left;
  font-size: 32px;
  margin-right: 10px;
}

body .alert a {
  color: #fff;
}

body .alert a:hover {
  color: #fff;
}

body .alert .close {
  opacity: 1;
  color: #fff;
  margin-left: auto;
  margin-right: 5px;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0px;
  text-shadow: none;
}

body .alert .close:hover {
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 375px) {
  body .alert {
    font-size: 12px;
  }
}

body #alert-info {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

body #alert-info .alert {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  body #alert-info {
    top: 60px;
  }
  body #alert-info .alert {
    width: 90%;
  }
}

body #accordion .form-control {
  padding: 5px 12px;
}

body .margin-btn {
  margin-bottom: 15px;
}

body .order-steps {
  list-style: none;
  padding: 0;
  width: 100%;
  display: none;
  margin-bottom: 55px;
}

body .order-steps li {
  width: 33.33333333%;
  padding-left: 20px;
  text-transform: uppercase;
}

body .order-steps li:first-child {
  padding-left: 0;
}

body .order-steps li:before {
  content: '';
  width: 100%;
  height: 10px;
  margin-bottom: 6px;
  float: left;
}

@media (max-width: 767px) {
  body .order-steps {
    margin-bottom: 25px;
  }
  body .order-steps li {
    text-align: center;
  }
  body .order-steps li span {
    display: none;
  }
  body .order-steps li:before {
    content: '';
    width: 100%;
    height: 10px;
    margin-bottom: 6px;
    float: left;
    display: none;
  }
  body .order-steps li:after {
    content: '';
    width: 100%;
    height: 10px;
    margin-bottom: 6px;
    display: inline-block;
  }
}

body .checkout-btn {
  margin-top: 60px;
}

body .mfp-content .mfp-figure .mfp-close {
  padding-right: 10px;
  right: 0;
}

body .mfp-content .mfp-figure .mfp-bottom-bar .mfp-title {
  padding-left: 10px;
}

body .mfp-content .mfp-figure .mfp-bottom-bar .mfp-counter {
  padding-right: 10px;
}

body .form-group .review-rating .excellent {
  margin-left: 10px;
}

body .form-group .review-rating .control-label {
  margin-left: 0;
  padding-left: 0;
}

body .form-group .review-rating label {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 15px;
  margin-left: 10px;
}

body .form-group .review-rating.radio-label label .check:after {
  left: -5px;
}

body .form-group .review-rating.radio-label label .check:before {
  left: -1px;
}

body #modal-agree .modal-dialog {
  text-align: center;
}

body #modal-agree .modal-dialog .modal-content {
  font-family: "Roboto", sans-serif;
  border-radius: 3px;
  border: none;
}

body #modal-agree .modal-dialog .modal-content .modal-header {
  padding-bottom: 10px;
  border: none;
}

body #modal-agree .modal-dialog .modal-content .modal-header .modal-title {
  margin-top: 40px;
  font-size: 32px;
}

body #modal-agree .modal-dialog .modal-content .modal-header .close {
  font-size: 24px;
  opacity: 1;
  text-shadow: 1px 0px 0px;
}

body #modal-agree .modal-dialog .modal-content .modal-body {
  font-size: 15px;
  padding-top: 0;
}

body #modal-agree .modal-dialog .modal-content .modal-body p {
  margin-bottom: 40px;
}

body .modal-backdrop.in {
  opacity: 0.3;
}

@media (min-width: 767px) {
  body .bootstrap-datetimepicker-widget.top:before {
    left: 22px;
  }
  body .bootstrap-datetimepicker-widget.top:after {
    left: 23px;
  }
  body .bootstrap-datetimepicker-widget.bottom:before {
    left: 22px;
  }
  body .bootstrap-datetimepicker-widget.bottom:after {
    left: 23px;
  }
  body .bootstrap-datetimepicker-widget.pull-right:before {
    left: auto;
    right: 6px;
  }
  body .bootstrap-datetimepicker-widget.pull-right:after {
    left: auto;
    right: 7px;
  }
}

body .owl-carousel .owl-buttons div {
  text-shadow: none;
}

body .col-lg-4 .product-layout .button-group, body .ol-md-4 .product-layout .button-group, body .col-sm-6 .product-layout .button-group {
  margin-top: 15px;
}

@media (max-width: 767px) {
  body .improved_tab .buttons {
    text-align: center !important;
  }
  body .improved_tab .buttons .btn {
    float: none !important;
    margin: 10px;
  }
  body #collapse-checkout-confirm {
    text-align: center;
  }
  body #collapse-checkout-confirm #button-pre-confirm {
    margin-top: 30px;
  }
  body #collapse-checkout-confirm .btn, body #collapse-checkout-confirm .pull-right {
    float: none !important;
  }
}

body #menu .dropdown:hover > .dropdown-menu {
  display: block !important;
}

@media (min-width: 768px) {
  body #menu .dropdown:hover .dropdown-menu {
    display: none;
  }
}

.ie9 body .showcase .wrap-panel [class*='-layout'] {
  margin: 20px auto !important;
}

.ie9 body .showcase .wrap-panel [class*='-layout']:nth-child(5) {
  clear: left;
}
