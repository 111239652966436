$url: '../image/';
$fonts-size: 15px;
$font: 'Roboto', sans-serif;
$font-header: 'Yeseva One', cursive;
body {
.short-name {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

  /* about us */ 
  .information {
    ul {
      padding-left: 5px;
      margin-bottom: 35px;
     li:before {
        content:  "—";
        position: relative;
        left: -5px;
      }
      li {
        list-style: none;
      }
        ul {
            padding-left: 30px;
            li {
            list-style-type: disc;
          }
           li:before {
            display: none;
          }
      }
    }
    ol {
      padding-left: 16px;
      margin-bottom: 35px;
      li {

      }
    }
  }
  /* about us */

  /* store module*/
  .store {
    .panel-default {
      padding-bottom: 20px;
      border-radius: 0;
      box-shadow: none;
    }
    p {
      margin-top: 20px;
      padding: 0 15px;
    }
    .general {
      a {
        padding: 5px 15px 5px 30px;
      }
    }
    a {
      font-family: $font;
      font-size: 15px;
      text-decoration: none;
      padding: 5px 10px;
      display: inline-block;
    }
    .panel {
      span {
        padding-left: 15px;
      }
    }
  }

  /* store module*/

  /* Manufacturer Page */
  .alphabet_list {
      .alphabet {
        font-size: 18px;
        a {
          font-size: 15px;
        }
      }
      .alphabet_h2 {
          font-size: 30px;
          padding: 20px 0;
          margin: 0;
          flex: 0 0 80px;
          font-family: $font-header;
          float: left;
          border: none;
        }
    .alphabet_category {
      line-height: 69px;
      margin-left: 0;
      margin-left: 70px;
      a {
        text-decoration: none;
         &:before {
            content: "\f0da";
            font-size: 15px;
            padding-right: 5px;
            display: inline-block;
            font-family: FontAwesome;
          }
      }
      span {
        padding-right: 6px;
      }
      .title {
        margin-left: 15px;
        float: left;
      }
    } 
  }

  /* Manufacturer Page */

  /* Search-list */

  .search-list {
    .dropdown-menu {
      min-width: 260px;
      padding: 0;
      margin-top: 3px;
      li {
        margin: 5px;
        font-size: 13px;
        display: flex;
        &:hover {
          cursor: pointer;
        }
        .title-search {
          padding-top: 5px;
        }
        span {
          img {
            padding: 10px;
            text-align: center;
          }
        }
      }
    }
  }

/* Search-list */

  /* Alerts */

    .alert {
      position: relative;
      line-height: 32px;
      color: #fff;
      padding: 4px 30px 4px 6px;
      i {
        float: left;
        font-size: 32px;
        margin-right: 10px;
      }
      a {
        color: #fff;
        &:hover {
         color: #fff;
        }
      }
    .close {
      opacity: 1;
      color: #fff;
      margin-left: auto;
      margin-right: 5px;
      position: absolute;
      right: 5px;
      top: 0;
      bottom: 0px;
      text-shadow: none;
      &:hover {
        color: transparentize(#fff, .2);
      }
    }
    @media (max-width:375px) {
        font-size: 12px;
      }
    }
    #alert-info {
      position: fixed;
      top: 0;
      z-index: 999;
      width: 100%;
      .alert {
        width: 80%;
        margin: 0 auto;
      }
    @media (max-width:767px) {
       top: 60px;
       .alert {
         width: 90%;
       }
      }
    }


  /* Alerts */

  /* Checkout */
  #accordion {
    .form-control {
      padding: 5px 12px;
    }
  }
  .margin-btn {
    margin-bottom: 15px;
  }
   .order-steps {
    list-style: none;
    padding: 0;
    width: 100%;
    display: none;
    margin-bottom: 55px;
      li {
      width: 33.33333333%;
      padding-left: 20px;
      text-transform: uppercase;
        &:first-child {
        padding-left: 0;
      }
      &:before {
        content: '';
        width: 100%;
        height: 10px;
        margin-bottom: 6px;
        float: left;
        }
      }
      @media (max-width:767px) {
          margin-bottom: 25px;
        li {
            text-align: center;
          span {
            display: none;
          }
        &:before {
            content: '';
            width: 100%;
            height: 10px;
            margin-bottom: 6px;
            float: left;
            display: none;
        }
        &:after {
            content: '';
            width: 100%;
            height: 10px;
            margin-bottom: 6px;
            display: inline-block;
        }
        }
      }  
    }
    
    .checkout-btn {
        margin-top: 60px;
    }

  /* checkout */

  /* magnific-popup */

  .mfp-content .mfp-figure {
    .mfp-close {
      padding-right: 10px;
      right: 0;
    }
    .mfp-bottom-bar {
      .mfp-title {
        padding-left: 10px;
      }
      .mfp-counter {
        padding-right: 10px;
      }
    }
  }


  /* magnific-popup */

  /* product page */

  .form-group {
    .review-rating {
      .excellent {
        margin-left: 10px;
      }
      .control-label {
        margin-left: 0;
        padding-left: 0;
      }
      label {
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 15px;
        margin-left: 10px;
      }
      &.radio-label label .check:after{
        left: -5px;
      }
      &.radio-label label .check:before{
        left: -1px;
      }
    }
  }

  /* product page*/

  /* modal window */

  #modal-agree {
    .modal-dialog {
      text-align: center;
      .modal-content {
        font-family: $font;
        border-radius: 3px;
        border: none;
          .modal-header {
            padding-bottom: 10px;
            border: none;
          .modal-title {
            margin-top: 40px;
            font-size: 32px;
          }
          .close {
            font-size: 24px;
            opacity: 1;
            text-shadow: 1px 0px 0px;
          }
        }
        .modal-body {
          font-size: 15px;
          padding-top: 0;
          p {
              margin-bottom: 40px;
          }
        }
      }
    }
  }
  .modal-backdrop.in {
    opacity: 0.3;
  }

  /* modal window */

  /* calendar */
 
  @media (min-width:767px) { 
      .bootstrap-datetimepicker-widget.top {
          &:before {
          left: 22px;
            }
          &:after {
            left: 23px;
          }
        }
      .bootstrap-datetimepicker-widget.bottom {
          &:before {
          left: 22px;
            }
          &:after {
            left: 23px;
          }
        }
    .bootstrap-datetimepicker-widget.pull-right  {
            &:before {
                left: auto;
                right: 6px;
              }
            &:after {
                left: auto;
                right: 7px;
            }
          }
      }
  /* calendar */

  /* carousel */

  .owl-carousel .owl-buttons div {
      text-shadow:none
  }

  /* carousel */
  .col-lg-4, .ol-md-4, .col-sm-6  {
    .product-layout {
        .button-group {
          margin-top: 15px;
        }
    }
  }

   /* Ordering */
   @media (max-width:767px) { 
      .improved_tab {
      .buttons {
          text-align: center!important;
          .btn {
            float: none!important;
            margin: 10px;
          }
        }
      }
      #collapse-checkout-confirm {
        #button-pre-confirm {
          margin-top: 30px;
        }
        text-align: center;
        .btn, .pull-right  {
          float: none!important;
        }
      }
    }
    /* Ordering */

    /* Maine menu */


    #menu .dropdown:hover > .dropdown-menu {
        display: block !important;
    }
    @media (min-width: 768px) {
        #menu .dropdown:hover .dropdown-menu {
            display: none;
        }
    }


    /* Maine menu */
  }
  .ie9 {
    body {
      .showcase {
          .wrap-panel [class*='-layout'] {
              margin: 20px auto!important;
          &:nth-child(5) {
            clear: left;
          }    
          }
      }
  }
  }


